<template>
  <section>
    <div class="top">
      <div>
        <p class="att_title">
          Attendance
        </p>
        <p class="att_subtitle">
          Class: {{ this.$route.params.id }}
        </p>
      </div>
      <div>
        <div class="top_search">
          <VSelect
            v-model="month"
            :items="attMonth"
            :hide-details="true"
            label="Month"
            flat
            solo
          />
          <div class="span3">
            <VTextField
              v-model="stdSearch"
              :hide-details="true"
              label="Search By Name"
              append-icon="search"
              solo
              flat
            />
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div
        v-if="attStat === 'success'"
        class="grid"
      >
        <VCard
          v-for="(std,index) in filteredStudents"
          :key="index"
          class="shadow"
        >
          <VListTile>
            <VListTileContent>
              <VListTileTitle class="att_main_txt">
                {{ std.name }}
              </VListTileTitle>
              <VListTileSubTitle class="att_sub_txt">
                {{ std.data[0].data.admNo }}
              </VListTileSubTitle>
            </VListTileContent>

            <VListTileAction>
              <VBtn
                icon
                @click="show = !show"
              >
                <VIcon>{{ show ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</VIcon>
              </VBtn>
            </VListTileAction>
          </VListTile>
          <VSlideYTransition>
            <section
              v-if="show"
              class="mt-3"
            >
              <div
                v-for="(data, idx) in std.data"
                :key="idx"
              >
                <VListTile>
                  <VListTileContent>
                    <VListTileTitle class="att_main_txt">
                      {{ data.data.date }}
                    </VListTileTitle>
                    <VListTileSubTitle class="att_sub_txt">
                      {{ data.data.status }}
                    </VListTileSubTitle>
                  </VListTileContent>

                  <VListTileAction>
                    <VIcon>
                      {{ data.data.status === 'present' ? 'done' : 'close' }}
                    </VIcon>
                  </VListTileAction>
                </VListTile>
              </div>
            </section>
          </VSlideYTransition>
        </VCard>
      </div>
      <div v-if="attStat === 'failed'">
        <div id="noData">
          No Attendance Found
        </div>
      </div>
      <div v-if="attStat === 'loading'">
        <div id="noData">
          <div>
            <div class="mb-4">
              <VProgressCircular
                indeterminate
                color="primary"
                :width="3"
                :size="40"
              />
            </div>
            Loading Data
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import api from '../../conf/api';

export default {
  data: () => ({
    show: false,
    students: null,
    date: moment().format('DD-MMMM-YYYY'),
    month: moment().format('MMMM'),
    year: moment().format('YYYY'),
    id: '',
    attStat: '',
    attMonth: [
      'January', 'February', 'March', 'April',
      'May', 'June', 'July', 'August', 'September',
      'October', 'November', 'December',
    ],
    stdSearch: '',
  }),
  computed: {
    filteredStudents() {
      return this.students.filter(student => student.name.toLowerCase()
        .match(this.stdSearch.toLowerCase()));
    },
  },
  watch: {
    month(v) {
      if (v) {
        this.newQuery();
      }
    },
  },
  beforeMount() {
    this.id = this.$route.params.id;
    const querySchema = {
      class: this.id,
      date: this.date,
      month: this.month,
      year: this.year,

    };
    this.attStat = 'loading';
    axios.post(`${api}/attendance/view`, querySchema)
      .then((res) => {
        if (res.data.status === 'success') {
          this.students = res.data.data;
          this.attStat = 'success';
        } else {
          this.attStat = 'failed';
          this.students = null;
        }
      });
  },
  methods: {
    newQuery() {
      const querySchema = {
        class: this.id,
        date: this.date,
        month: this.month,
        year: this.year,

      };
      this.attStat = 'loading';
      axios.post(`${api}/attendance/view`, querySchema)
        .then((res) => {
          if (res.data.status === 'success') {
            this.students = res.data.data;
            this.attStat = 'success';
          } else {
            this.attStat = 'failed';
            this.students = null;
          }
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Attendance/view';
</style>
